



















































































































































































































































































































@import '../assets/css/global.css';
/*去除百度地图版权*/
.title-station {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	font-size: 22px;
	line-height: 80px;
	color: #E2E2E2;
	text-align: center;
}

#map {
	float: left;
	margin: auto;
	width: 100%;
	height: 100%;
}
.BMap_cpyCtrl {
	display: none !important;
}

.anchorBL a img {
	display: none !important;
}
.BMap_bubble_title {
	text-align: left !important;
	background: transparent !important;
	font-family: "微软雅黑 ";
	font-size: 16px !important;
	color: #CDF9FF !important;
	font-weight: 600 !important;
}
.BMap_bubble_content{
	margin-top: 10px !important;
	font-size: 13px; 
	color: rgb(215, 215, 220);
}
.BMap_pop .BMap_top {
	background: rgba(48, 65, 86, .8) !important;
	border: 0 !important;
}

.BMap_pop .BMap_center {
	width: 260px !important;
	border: 0 !important;
	background: rgba(48, 65, 86, .8) !important;
}

.BMap_pop .BMap_bottom {
	border: 0 !important;
	background: rgba(48, 65, 86, .8) !important;
}

.BMap_pop div:nth-child(3) {
	background: transparent !important;
}

.BMap_pop div:nth-child(3) div {
	border-radius: 7px;
	background: rgba(48, 65, 86, .8) !important;
	border: 0 !important;
}

.BMap_pop div:nth-child(1) {
	border-radius: 7px 0 0 0;
	background: transparent !important;
	border: 0 !important;
}

.BMap_pop div:nth-child(1) div {
	background: rgba(48, 65, 86, .8) !important;
}

.BMap_pop div:nth-child(5) {
	border-radius: 0 0 0 7px;
	background: transparent !important;
	border: 0 !important;
}

.BMap_pop div:nth-child(5) div {
	border-radius: 7px;
	background: rgba(48, 65, 86, .8) !important;
}

.BMap_pop div:nth-child(7) {
	background: transparent !important;
	left: 226px;
}

.BMap_pop div:nth-child(7) div {
	border-radius: 7px;
	background: rgba(48, 65, 86, .8) !important;
}
/*替换关闭按钮*/

img[src="http://api0.map.bdimg.com/images/iw_close1d3.gif"] {
	content: url('http://vino.test.bidostar.cn/iw_close1d3.png');
}
/*替换剪头*/

img[src="https://api.map.baidu.com/images/iw3.png"] {
	opacity: .7;
	margin-top: -692px !important;
	filter: alpha(opacity=70);
	content: url('../assets/images/iw3.png') !important;
}

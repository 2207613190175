























































































































































































































































































































































































































@import '../assets/css/global.css';
.header {
	padding: 0;
	border-bottom: 1px solid #003a68;
}

.sider {
	border-right: 1px solid #003a68;
}

.operateUser {
	cursor: pointer;
	text-align: center;
	width: 60px;
}

.operateUser:hover {
	background: #409eff;
	color: #fff;
}


.nav-top img{
	left: 0;
	z-index: -1;
	position: absolute;
}
.nav-top {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	text-align: center;
}

.nav-top-title {
	line-height: 60px;
	color: #CDF9FF;
	font-size: 24px;
	font-weight: 600;
}

.nav-top-time {
	position: absolute;
	top: 0;
	font-size: 14px;
}

.nav-top-time span {
	display: block;
	color: #CDF9FF;
	line-height: 20px;
}

.energySaving .capacity {
	right: 0px;
	top: 20px;
}

.energySaving .power {
	right: 0px;
	top: 140px;
}

.energySaving .stationCount {
	right: 0px;
	top: 260px;
}
.capacity {
	color: #CDF9FF;
	width: 126px;
	height: 100px;
	position: absolute;
	top: 155px;
	right: 0px;
	z-index: 13;
}

.capacity .icon {
	border-radius: 5px;
	width: 48px;
	height: 48px;
	left: 39px;
	background: rgba(27, 202, 1, 0.2);
	position: relative;
}

.capacity .home_data {
	margin-top: 5px;
	width: 100%;
	text-align: center;
	font-size: 19px;
}

.capacity .home_name {
	width: 100%;
	text-align: center;
	font-size: 13px;
}

.power {
	color: #CDF9FF;
	width: 126px;
	height: 100px;
	position: absolute;
	top: 270px;
	right: 0px;
	z-index: 13;
}

.power .icon {
	border-radius: 5px;
	width: 48px;
	height: 48px;
	left: 39px;
	background: rgba(27, 202, 1, 0.2);
	position: relative;
}

.power .home_data {
	width: 100%;
	margin-top: 5px;
	text-align: center;
	font-size: 19px;
}

.power .home_name {
	width: 100%;
	text-align: center;
	font-size: 13px;
}

.stationCount {
	color: #CDF9FF;
	width: 126px;
	height: 100px;
	position: absolute;
	top: 385px;
	right: 0px;
	z-index: 13;
}

.stationCount .icon {
	border-radius: 5px;
	width: 48px;
	height: 48px;
	left: 39px;
	background: rgba(27, 202, 1, 0.2);
	position: relative;
}

.stationCount .home_data {
	width: 100%;
	margin-top: 5px;
	text-align: center;
	font-size: 19px;
}

.stationCount .home_name {
	width: 100%;
	text-align: center;
	font-size: 13px;
}

.energySaving {
	width: 126px;
	height: 380px;
	position: absolute;
	top: 540px;
	right: 0;
	z-index: 13;
}

.statisticalData{
	width: 300px;
	height: 880px;
	position: absolute;
	z-index: 13;
	top: 60px;
	left: 10px;
	color: #CDF9FF;
}
.statisticalData .title{
	height: 32px;
	text-indent: 10px;
	line-height: 32px;
	background:url(../images/bg_menu.png);
}
.statisticalData .list{
	margin-top: 10px;
	margin-bottom: 10px;
}
.home_list .home_text{
	padding-top: 10px;
	line-height: 30px;
	padding-right: 20px;
	padding-bottom: 10px;
	height: 35px;
}
span.left{
	width: 40px;
}
span.name{
	font-size: 13px;
}
span.right{
	margin-right: 20px;
	font-size: 19px;
	font-weight: bolder;
	width: 125px;
	text-align: end;
}
.hr{
	margin-right: 20px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.homeChart{
	padding: 0;
	height: 280px;
	width: 100%;
	overflow: hidden;
	background: rgba(4, 76, 161, 0.1);
}
.logout{
	width: 48px;
	height: 48px;
	position: absolute;
	top: 25px;
	font-size: 24px;
	right: 39px;
	border-radius: 24px;
    background: rgba(0, 222, 255, 0.2);
}
.home-icon{
	left: 12px;
	position: absolute;
	top: 12px;
}
.btnLayer {
	width: 48px;
	height: 48px;
	position: absolute;
	font-size: 24px;
	top: 90px;
	right: 39px;
	z-index: 14;
}

.btnLayer .search {
	width: 48px;
	height: 48px;
	position: relative;
}

.btnLayer .search .searchInner {
	position: absolute;
	width: 48px;
	height: 48px;
	border-radius: 24px;
	background: rgba(0, 222, 255, 0.2);
	top: 0;
	right: 0;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}

.btnLayer .search .open {
	width: 240px;
	background: rgba(1, 239, 227, 0.5);
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}

.btnLayer .search .icoSearch {
	display: block;
	width: 24px;
	height: 24px;
	background: url(../images/s_ico8.png);
}

.btnLayer .search a.searchBtn .icoSearch {
	position: relative;
	top: 12px;
	left: 12px;
}

.btnLayer .search form {
	width: 200px;
	height: 24px;
	padding: 12px 20px;
	display: none;
}

.btnLayer .search form button.icoSearch {
	float: left;
	margin-right: 10px;
	cursor: pointer;
}

.btnLayer .search form input {
	float: left;
	margin-left: 20px;
	width: 166px;
	height: 24px;
	line-height: 24px;
	color: #fff;
}

.btnLayer .search form input:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #fff;
	opacity: 1;
}

.btnLayer .search form input::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #fff;
	opacity: 1;
}

.btnLayer .search form input:-ms-input-placeholder {
	color: #fff;
	opacity: 1;
}

.btnLayer .search form input::-webkit-input-placeholder {
	color: #fff;
	opacity: 1;
}

@media screen and (max-width: 1919px) and (min-width: 1680px) {}

@media screen and (max-width: 1679px) and (min-width: 1440px) {
	.nav-top {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: rgba(3, 21, 24, 0.7);
		text-align: center;
	}
	.nav-top-title {
		line-height: 54px;
		color: #ffffff;
		font-size: 21px;
		font-weight: 600;
	}
	.nav-top-time {
		position: absolute;
		top: 0;
		right: 10px;
		padding: 10px;
		font-size: 13px;
	}
	.nav-top-time span {
		display: block;
		color: #ffffff;
		line-height: 20px;
	}
	.energySaving .capacity {
		right: 0px;
		top: 20px;
	}
	.energySaving .power {
		right: 0px;
		top: 140px;
	}
	.energySaving .stationCount {
		right: 0px;
		top: 260px;
	}
	.capacity {
		width: 90px;
		height: 82px;
		top: 125px;
		right: 0px;
	}
	.capacity .icon {
		border-radius: 5px;
		width: 36px;
		height: 36px;
		left: 25px;
		position: relative;
	}
	.capacity .home_data {
		margin-top: 5px;
		width: 100%;
		text-align: center;
		font-size: 16px;
	}
	.capacity .home_name {
		width: 100%;
		text-align: center;
		font-size: 11px;
	}
	.power {
		color: #CDF9FF;
		width: 90px;
		height: 82px;
		position: absolute;
		top: 225px;
		right: 0px;
		z-index: 13;
	}
	.power .icon {
		border-radius: 5px;
		width: 36px;
		height: 36px;
		left: 25px;
		position: relative;
	}
	.power .home_data {
		width: 100%;
		margin-top: 5px;
		text-align: center;
		font-size: 16px;
	}
	.power .home_name {
		width: 100%;
		text-align: center;
		font-size: 11px;
	}
	.stationCount {
		color: #CDF9FF;
		width: 90px;
		height: 82px;
		position: absolute;
		top: 325px;
		right: 0px;
		z-index: 13;
	}
	.stationCount .icon {
		border-radius: 5px;
		width: 36px;
		height: 36px;
		left: 25px;
		position: relative;
	}
	.stationCount .home_data {
		width: 100%;
		margin-top: 5px;
		text-align: center;
		font-size: 16px;
	}
	.stationCount .home_name {
		width: 100%;
		text-align: center;
		font-size: 11px;
	}
	.energySaving {
		width: 90px;
		height: 380px;
		position: absolute;
		top: 500px;
		right: 0;
		z-index: 13;
	}
	.logout{
		width: 36px;
		height: 36px;
		position: absolute;
		top: 25px;
		font-size: 24px;
		right: 27px;
		border-radius: 24px;
	    background: rgba(0, 222, 255, 0.2);
	}
	.home-icon{
		left: 6px;
		position: absolute;
		top: 6px;
	}
	.btnLayer {
		width: 36px;
		height: 36px;
		top: 70px;
		right: 27px;
	}
	.btnLayer .search {
		width: 36px;
		height: 36px;
	}
	.btnLayer .search .searchInner {
		width: 36px;
		height: 36px;
		border-radius: 18px;
	}
	.btnLayer .search .open {
		width: 240px;
	}
	.btnLayer .search .icoSearch {
		display: block;
		width: 20px;
		height: 20px;
		background: url(../images/s_ico8.png);
		background-size: 100% 100%;
	}
	.btnLayer .search a.searchBtn .icoSearch {
		position: relative;
		top: 8px;
		left: 8px;
	}
	.btnLayer .search form {
		width: 200px;
		height: 24px;
		padding: 6px 15px;
	}
	.btnLayer .search form button.icoSearch {
		margin-right: 10px;
		margin-top: 2px;
	}
	.btnLayer .search form input {
		width: 166px;
	}
}

@media screen and (max-width: 1439px) and (min-width: 1366px) {
	.nav-top {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: rgba(3, 21, 24, 0.7);
		text-align: center;
	}
	.nav-top-title {
		line-height: 48px;
		color: #ffffff;
		font-size: 20px;
		font-weight: 600;
	}
	.nav-top-time {
		position: absolute;
		top: 0;
		right: 10px;
		padding: 10px;
		font-size: 13px;
	}
	.nav-top-time span {
		display: block;
		color: #ffffff;
		line-height: 20px;
	}
	.energySaving .capacity {
		right: 0px;
		top: 20px;
	}
	.energySaving .power {
		right: 0px;
		top: 140px;
	}
	.energySaving .stationCount {
		right: 0px;
		top: 260px;
	}
	.capacity {
		width: 90px;
		height: 82px;
		top: 125px;
		right: 0px;
	}
	.capacity .icon {
		border-radius: 5px;
		width: 36px;
		height: 36px;
		left: 25px;
		position: relative;
	}
	.capacity .home_data {
		margin-top: 5px;
		width: 100%;
		text-align: center;
		font-size: 16px;
	}
	.capacity .home_name {
		width: 100%;
		text-align: center;
		font-size: 11px;
	}
	.power {
		color: #CDF9FF;
		width: 90px;
		height: 82px;
		position: absolute;
		top: 225px;
		right: 0px;
		z-index: 13;
	}
	.power .icon {
		border-radius: 5px;
		width: 36px;
		height: 36px;
		left: 25px;
		position: relative;
	}
	.power .home_data {
		width: 100%;
		margin-top: 5px;
		text-align: center;
		font-size: 16px;
	}
	.power .home_name {
		width: 100%;
		text-align: center;
		font-size: 11px;
	}
	.stationCount {
		color: #CDF9FF;
		width: 90px;
		height: 82px;
		position: absolute;
		top: 325px;
		right: 0px;
		z-index: 13;
	}
	.stationCount .icon {
		border-radius: 5px;
		width: 36px;
		height: 36px;
		left: 25px;
		position: relative;
	}
	.stationCount .home_data {
		width: 100%;
		margin-top: 5px;
		text-align: center;
		font-size: 16px;
	}
	.stationCount .home_name {
		width: 100%;
		text-align: center;
		font-size: 11px;
	}
	.energySaving {
		width: 90px;
		height: 380px;
		position: absolute;
		top: 500px;
		right: 0;
		z-index: 13;
	}
	.logout{
		width: 36px;
		height: 36px;
		position: absolute;
		top: 25px;
		font-size: 24px;
		right: 27px;
		border-radius: 24px;
	    background: rgba(0, 222, 255, 0.2);
	}
	.home-icon{
		left: 6px;
		position: absolute;
		top: 6px;
	}
	.btnLayer {
		width: 36px;
		height: 36px;
		top: 70px;
		right: 27px;
	}
	.btnLayer a {
		display: block;
		width: 30px;
		height: 30px;
	}
	.btnLayer .search {
		width: 36px;
		height: 36px;
	}
	.btnLayer .search .searchInner {
		width: 36px;
		height: 36px;
		border-radius: 18px;
	}
	.btnLayer .search .open {
		width: 240px;
	}
	.btnLayer .search .icoSearch {
		display: block;
		width: 20px;
		height: 20px;
		background: url(../images/s_ico8.png);
		background-size: 100% 100%;
	}
	.btnLayer .search a.searchBtn .icoSearch {
		position: relative;
		top: 8px;
		left: 8px;
	}
	.btnLayer .search form {
		width: 200px;
		height: 24px;
		padding: 6px 15px;
	}
	.btnLayer .search form button.icoSearch {
		margin-right: 10px;
		margin-top: 2px;
	}
	.btnLayer .search form input {
		width: 166px;
	}
}

@media screen and (max-width: 1365px) {
	.nav-top {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: rgba(3, 21, 24, 0.7);
		text-align: center;
	}
	.nav-top-title {
		line-height: 42px;
		color: #ffffff;
		font-size: 19px;
		font-weight: 600;
	}
	.nav-top-time {
		position: absolute;
		top: 0;
		right: 10px;
		padding: 10px;
		font-size: 13px;
	}
	.nav-top-time span {
		display: block;
		color: #ffffff;
		line-height: 20px;
	}
	.energySaving .capacity {
		right: 0px;
		top: 20px;
	}
	.energySaving .power {
		right: 0px;
		top: 140px;
	}
	.energySaving .stationCount {
		right: 0px;
		top: 260px;
	}
	.capacity {
		width: 90px;
		height: 82px;
		top: 125px;
		right: 0px;
	}
	.capacity .icon {
		border-radius: 5px;
		width: 36px;
		height: 36px;
		left: 25px;
		position: relative;
	}
	.capacity .home_data {
		margin-top: 5px;
		width: 100%;
		text-align: center;
		font-size: 16px;
	}
	.capacity .home_name {
		width: 100%;
		text-align: center;
		font-size: 11px;
	}
	.power {
		color: #CDF9FF;
		width: 90px;
		height: 82px;
		position: absolute;
		top: 225px;
		right: 0px;
		z-index: 13;
	}
	.power .icon {
		border-radius: 5px;
		width: 36px;
		height: 36px;
		left: 25px;
		position: relative;
	}
	.power .home_data {
		width: 100%;
		margin-top: 5px;
		text-align: center;
		font-size: 16px;
	}
	.power .home_name {
		width: 100%;
		text-align: center;
		font-size: 11px;
	}
	.stationCount {
		color: #CDF9FF;
		width: 90px;
		height: 82px;
		position: absolute;
		top: 325px;
		right: 0px;
		z-index: 13;
	}
	.stationCount .icon {
		border-radius: 5px;
		width: 36px;
		height: 36px;
		left: 25px;
		position: relative;
	}
	.stationCount .home_data {
		width: 100%;
		margin-top: 5px;
		text-align: center;
		font-size: 16px;
	}
	.stationCount .home_name {
		width: 100%;
		text-align: center;
		font-size: 11px;
	}
	.energySaving {
		width: 90px;
		height: 380px;
		position: absolute;
		top: 500px;
		right: 0;
		z-index: 13;
	}
	.logout{
		width: 36px;
		height: 36px;
		position: absolute;
		top: 25px;
		font-size: 24px;
		right: 27px;
		border-radius: 24px;
	    background: rgba(0, 222, 255, 0.2);
	}
	.home-icon{
		left: 6px;
		position: absolute;
		top: 6px;
	}
	.btnLayer {
		width: 36px;
		height: 36px;
		top: 70px;
		right: 27px;
	}
	.btnLayer a {
		display: block;
		width: 30px;
		height: 30px;
	}
	.btnLayer .search {
		width: 36px;
		height: 36px;
	}
	.btnLayer .search .searchInner {
		width: 36px;
		height: 36px;
		border-radius: 18px;
	}
	.btnLayer .search .open {
		width: 240px;
	}
	.btnLayer .search .icoSearch {
		display: block;
		width: 20px;
		height: 20px;
		background: url(../images/s_ico8.png);
		background-size: 100% 100%;
	}
	.btnLayer .search a.searchBtn .icoSearch {
		position: relative;
		top: 8px;
		left: 8px;
	}
	.btnLayer .search form {
		width: 200px;
		height: 24px;
		padding: 6px 15px;
	}
	.btnLayer .search form button.icoSearch {
		margin-right: 10px;
		margin-top: 2px;
	}
	.btnLayer .search form input {
		width: 166px;
	}
}